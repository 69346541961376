/** @jsx jsx */
import React from 'react';
import { jsx, Box, Grid, Link as AnchorLink } from 'theme-ui';
import BlockContent from '@sanity/block-content-to-react';
import { ThemeHeader, ImageFullWidth } from '../../components';
import { CTALink } from '..';
import AccordionComponent from '../AccordionComponent';
import { serializers } from '../../utils/blockMarks';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import { mapLocationURL } from '../../assets/js/utils';

const ContactBlock = (props) => {
  const { mainHeadingTextBlock, locations, address, contactNo, cardList } = props;

  const [ContactTwoColumnRef, isContactTwoColumnVisible] = useElementOnScreen();
  const [ContactThreeColumnRef, isContactThreeColumnVisible] = useElementOnScreen();
  const [ContactCardsColumnRef, isContactCardsColumnVisible] = useElementOnScreen();

  const renderLocationContent = (data) => {
    return (
      <Box sx={locationContentStyles.base}>
        <ThemeHeader.H3 styles={locationContentStyles.headline}>{data?.heading}</ThemeHeader.H3>
        <Box sx={locationContentStyles.link}>
          <AnchorLink href={`mailto:${data?.email}`}>{data?.email}</AnchorLink>
        </Box>
        <Box sx={locationContentStyles.link}>
          <AnchorLink href={`tel:${data?.phoneNo}`}>{data?.phoneNo}</AnchorLink>
        </Box>
      </Box>
    );
  };
  const renderThreeColumnTextComponent = (locList) => {
    return (
      <Box
        ref={ContactThreeColumnRef}
        sx={{ ...threeColumnTextStyles.base, ...{ transition: 'opacity 1s ease 0.25s', opacity: isContactThreeColumnVisible ? 1 : 0 } }}
      >
        <Grid gap="15px" columns={[1, null, 3]}>
          {locList.map((loc) => (
            <Box key={loc._key} sx={threeColumnTextStyles.col}>
              {renderLocationContent(loc)}
            </Box>
          ))}
        </Grid>
      </Box>
    );
  };
  const renderTwoColumnTextComp = () => {
    return (
      <Box
        ref={ContactTwoColumnRef}
        sx={{ ...twoColumnTextStyles.base, ...{ transition: 'opacity 1s ease 0.25s', opacity: isContactTwoColumnVisible ? 1 : 0 } }}
      >
        <Grid gap="15px" columns={[1, null, 2]} sx={twoColumnTextStyles.inner}>
          <Box sx={twoColumnTextStyles.left}>
            {mainHeadingTextBlock && (
              <Box>
                {mainHeadingTextBlock?.heading && (
                  <Box sx={twoColumnTextStyles.headline}>
                    <ThemeHeader.H2>{mainHeadingTextBlock?.heading}</ThemeHeader.H2>
                  </Box>
                )}
                {mainHeadingTextBlock?.text && (
                  <Box sx={twoColumnTextStyles.content}>
                    <BlockContent blocks={mainHeadingTextBlock?.text} serializers={serializers} />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box sx={twoColumnTextStyles.right}>
            {mainHeadingTextBlock?.ctaLinks && (
              <Box sx={twoColumnTextStyles.cta}>
                {mainHeadingTextBlock?.ctaLinks.map((link) => link?.link?.showLink && <CTALink key={link._key} linkData={link} />)}
              </Box>
            )}
            {address && (
              <Box sx={twoColumnTextStyles.address}>
                <AnchorLink target="__blank" href={mapLocationURL} sx={twoColumnTextStyles.link}>
                  <BlockContent blocks={address} serializers={serializers} />
                </AnchorLink>
              </Box>
            )}
            {contactNo && (
              <Box sx={twoColumnTextStyles.contactInfo}>
                <AnchorLink href={`tel:${contactNo}`}>{contactNo}</AnchorLink>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    );
  };
  const renderCardView = (card) => {
    return (
      <Box ref={ContactCardsColumnRef} key={card._key} sx={{ transition: 'opacity 1s ease 0.25s', opacity: isContactCardsColumnVisible ? 1 : 0 }}>
        <ImageFullWidth src={card.cardImage?.asset?._ref} alt="" />
      </Box>
    );
  };
  return (
    <>
      <Box sx={contactBlockStyles.base}>
        <Box sx={contactBlockStyles.top}>{renderTwoColumnTextComp()}</Box>
        {locations && (
          <Box sx={contactBlockStyles.bottom}>
            <Box sx={contactBlockStyles.bottomInner}>{renderThreeColumnTextComponent(locations)}</Box>
          </Box>
        )}
        {cardList && (
          <Grid gap="8px" columns={[1, null, 2]}>
            {cardList?.map((item) => renderCardView(item))}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ContactBlock;

const contactBlockStyles = {
  base: {
    backgroundColor: 'backgroundInverted',
    color: 'textInverted',
    '&:after': {
      content: '""',
      display: 'block',
      bg: 'accent',
      pb: 5,
    },
  },
  top: {
    py: [5, null, 8],
  },
  bottom: {
    px: 4,
  },
  bottomInner: {
    pt: [null, null, 5],
    pb: [4, null, null],
    mx: 'auto',
    maxWidth: 1100,
    borderTop: '1px solid',
    variant: 'styles.goldBorder',
  },
};

const locationContentStyles = {
  headline: {
    fontSize: 6,
  },
  link: {
    variant: 'styles.pSmall',
  },
};

const threeColumnTextStyles = {
  base: {
    py: 4,
  },
  col: {
    py: [4, null, 5],
  },
};
const twoColumnTextStyles = {
  base: {
    py: [6, null, 8],
    px: 4,
  },
  inner: {
    maxWidth: 1100,
    mx: 'auto',
  },
  left: {
    maxWidth: 530,
    mb: [5, null, 0],
  },
  headline: {
    pb: 2,
  },
  content: {
    variant: 'styles.p',
  },
  right: {
    maxWidth: 240,
    ml: [null, null, 'auto'],
  },
  cta: {
    mb: 3,
  },
  address: {
    variant: 'styles.pSmall',
    mb: 3,
  },
  contactInfo: {
    variant: 'styles.pSmall',
  },
  link: {
    color: 'textInverted',
    textDecoration: 'none',
  },
};
